.header {
  ion-toolbar {
    padding: 0px;
    ion-grid {
      padding: 0px;
    }
    .header-content {
      background: var(--ion-color-primary) !important;
      border-radius: 0px 0px 50px 50px;
      color: white;
      padding-bottom: 6px;
    }
  }
}