.main-grid, .main-row
{
  height: 100%;
}

.main-row .the-holder
{
  background: white;
  border-radius: 12px;
  width: 100%;
}

.the-holder .title-row
{
  padding-left:10px;
}


.the-holder .title-row h4
{
  color: #002251;
  font-family: 'Black';
  margin: 0;
}

.the-holder .title-row p
{
  padding-top: 5px ;
  color: var(--ion-color-primary);
  font-family: 'Black';
  margin: 0;
}

.cat-row
{
  padding: 0 ;
}

.cat-col ion-button
{
  --background: white ;
  --border-radius: 5px ;
  --box-shadow:0px 14px 36px rgba(52, 153, 246, 0.2) ;
  height: 125px ;
  width: 100% ;
}

.cat-col ion-button img
{
  height: 70px;
}

.cat-col ion-button p
{
  color: var(--ion-color-primary) ;
  margin-top: 5px ;
  text-transform: none ;
}