body {
  background-color: #312783;
}

.toolbar-container {
  padding: 0 !important;
}

.chart-container {
  overflow: visible;
  width: 100%;

  svg {
    overflow: visible;
  }
}

ion-tabs {
  background: white;
}

ion-tab-bar {
  background: white;
  max-height: 56px;
  height: 95px;
  max-height: 95px;
  border: unset;
  //box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.tab-selected ion-icon {
  color: white;
  padding-bottom: 9px;
}

.tab-selected, ion-tab-button {
  background: #2c2c2c;
}

#tab-button-tab1,
#tab-button-tab2,
#tab-button-tab3,
#tab-button-tab4,
#tab-button-tab5 {
  a.button-native {
    padding-inline-end: 0px !important;
    padding-inline-start: 0px !important;
  }
  background: var(--ion-color-primary);
  --border: none;
  max-height: 72px;
  margin-top: 39px;
  max-width: 78px;
  width: 78px;
  ion-label {
    font-size: 8px;
  }

  svg {
    color: var(--ion-color-light-inactive);
  }
}

ion-tab-button:hover {
  svg, ion-icon, ion-label {
    color: var(--ion-color-light) !important;
    --ion-color-base: white !important;
  }
}

#tab-button-tab1 {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}

#tab-button-tab5 {
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}

#tab-button-tab3 {
  div {
      background: white;
      border-radius: 50% !important;
      width: 82px !important;
      height: 82px !important;
      margin-top: -111px !important;
      //box-shadow: 0 0 0 15px white;
      position: relative;
  }
}

#tab-button-tab5.tab-selected ion-icon {
  padding-bottom: 0px;
}

#tab-button-tab1 > ion-icon:after,
#tab-button-tab2 > ion-icon:after,
#tab-button-tab4 > ion-icon:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 7px;
  position: absolute;
  transition: width 0.4s ease 0s, background-color .4s ease;
  width: 0;
  border-radius: 5px;
}

#tab-button-tab1.tab-selected > ion-icon:after,
#tab-button-tab2.tab-selected > ion-icon:after,
#tab-button-tab4.tab-selected > ion-icon:after {
  width: 24px;
  background: purple;
}

ion-fab {
  padding-bottom: env(safe-area-inset-bottom);
  position: absolute;
  bottom: 52px;
  box-shadow: none;
}

.ion-flex {
  display: flex;
}

ion-avatar {
  background: #FAE4D9;
}

ion-item-divider {
  background: none;
  padding: 0;
  ion-card {
    margin: 2px;
    margin-top: -2px;
    width: 100%;
  }
}

ion-header {
  background: white;
}

ion-radio-group {
  ion-item::part(native) {
    background: rgba(0, 0, 0, 0) !important;

    border-radius: 20px;
  }
  ion-item {
    --inner-border-width: 0px 0px 0px 0px;
    ion-label {
      font-size: 12px !important;
    }
  }
}

ion-radio {
  --border-radius: 100px;
  --inner-border-radius: 100px;

  --color: #ddd;
  --color-checked: var(--ion-color-primary);
}

ion-radio.ios {
  width: 20px;
  height: 20px;

  border: 2px solid #ddd;
  border-radius: 100px;
}

.radio-checked.ios {
  border-color: var(--ion-color-primary);
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  ion-button {
    width: 42px;
    height: 24px;
  }
}