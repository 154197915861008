.skip-button
{
    color:var(--ion-color-primary) !important;
    text-transform: none !important;
}

.main-row 
{
    height: 100% !important;
    width: 100% !important;
}


.intro .img-holder img
{
    height: 100vw;
    margin-top: 20vw;
}


.bottom-holder
{
    background: white;
    bottom: 0;
    z-index: 20;
}


.slide-content .sm-detail
{

    color: #77869E;
    font-family: 'Medium';
    font-size: 14px;
    margin-top: 10px !important;
    padding-bottom: 20px !important;
}


h4
{
    color: #042C5C;
    font-size: 16px;
}



.btns-holder
{
    margin: 0 10px;
    padding-bottom: 10px !important;
}

.btn
{
    --border-radius: 10px;
    display: inline-block !important;
    height: 3rem !important;
    margin: 0 5px !important;
    text-transform: none;
    width: 45% !important;
}

#signup
{
    --background: transparent;
    --border-color: #77869E;
    --border-size:0.1px;
    --border-style: solid;
    --color: #77869E;
}

