
main {
  background: url('../../../public/assets/imgs/background.svg');
}

ion-header {
  display: flex;
  align-items: center;
}
.stop-button {
  height: 3rem !important;
}

.head-title {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.chats-empty-div {
  background: #0A15D5;
  height: 120px;
}

.chats .custom-icon-chat-icon-1 {
  font-size: 40px;
  color: white;
}

.message-received-div {
  //margin: 10px;
}

.message-content-received {
  display: flex;
  width: 70% !important;
  align-items: flex-end;

  ion-avatar {
    margin-right: 12px;
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    background: #FAE4D9;
  }
}

.message-received {
  min-width: 126px;
  background-color: rgb(236, 236, 236);
  text-align: left;
  border-radius: 25px;
  border-bottom-left-radius: 0px;
  width: auto;
  margin-left: 4%;
  padding: 12px;
  padding-left: 24px;
  padding-right: 15px;
  font-size: 12px;
  display: flex;
  ion-spinner {
    height: 12px;
  }
  &.loader {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }
}

.message-sent-div {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.message-content-sent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row-reverse;

  ion-avatar {
    margin-left: 12px;
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    background: #FAE4D9;
  }
}

.recieved-time {
  left: 15px;

  .time {
    font-size: 10px;
  }
}

.sent-time {
  text-align: end;
  right: 20px;
  position: absolute;

  .time {
    font-size: 10px;
  }

  span.custom-icon-chat-completed-tick-icon {
    font-size: 14px;
    padding-left: 5px
  }
}

.message-sent {
  position: relative;
  background-color: var(--ion-color-primary);
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  color: white;
  width: auto;
  display: inline-block;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  margin: 15px 0 8px 0;
}

.message-sent-start {
  border-radius: 25px;
  border-bottom-right-radius: 2px;
}

.message-sent-end {
  border-radius: 25px;
  border-top-right-radius: 2px;
}

.message-received-start {
  border-radius: 25px;
  border-bottom-left-radius: 2px;
}

.message-received-end {
  border-radius: 25px;
  border-top-left-radius: 2px;
}


.background-content {
  --background: #f5f5f5 !important;
  font-size: 14px;
}

.message-text {
  background: #fff;
  padding: 6px 15px;
  height: 34px;
  line-height: 20px;
  font-size: 17px;
  margin: 10px 0;
}

.msg-button {
  padding-left: 5px;
  padding-bottom: 5px;
  border-color: transparent;
  color: #488aff;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}

.message-option {
  position: relative;
  background-color: var(--ion-color-primary);
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  color: white;
  width: auto;
  display: inline-block;
  padding: var(--ion-padding-1);
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
}

.footer-container {
  width: 100%;
  .type-message {
    background: var(--ion-background);
    max-height: 40vh;
    overflow-y: scroll;
  }
  ion-button  {
    min-width: 126px;
    --border-radius: 100px;
    float: right;
  }
}

ion-footer {
  padding: var(--ion-padding-1);
  background: var(--ion-background);
  &.footer-md::before,
  .footer-ios::before {
    background-image: none;
  }
}

.timer-container {
  padding: 12px;
  height: 56px;
  display: flex;
  align-items: center;
}