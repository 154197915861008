.login {
  margin-top: 2rem;
  ion-avatar {
    margin-top: 2rem;
    width: 84px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.toolbar {
  .toolbar-container {
      padding: 0!important;
  }
}