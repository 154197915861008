/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  --animate-delay: 0.3s;
  /** primary **/
  --ion-color-primary: #312783;
  --ion-color-primary-rgb: 49, 39, 131;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003eb4;
  --ion-color-primary-tint: #1a59d1;
  --ion-padding-1: 4px;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-inactive: #b4b4b4;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --padding-start: 0px;
  --padding-end: 0px;

  --ion-toolbar-background: #FFFF;
}

@font-face {
  font-family: 'Black';
  font-style: normal;
  src: url('../assets/fonts/Avenir-Black.ttf');
}

@font-face {
  font-family: 'Heavy';
  font-style: normal;
  src: url('../assets/fonts/Avenir-Heavy.ttf');
}


@font-face {
  font-family: 'Light';
  font-style: normal;
  src: url('../assets/fonts/Avenir-Light.ttf');
}

@font-face {
  font-family: 'Medium';
  font-style: normal;
  src: url('../assets/fonts/Avenir-Medium.ttf');
}

@font-face {
  font-family: 'Roman';
  font-style: normal;
  src: url('../assets/fonts/Avenir-Roman.ttf');
}

@font-face {
  font-family: 'Courier';
  font-style: normal;
  src: url('../assets/fonts/courier.ttf');
}


.flex {
  display: flex;
}

.flexd {
  flex-direction: column;
}


.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.jc-start {
  justify-content: flex-start;
}


.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

ion-button
{
  text-transform: none;
}

ion-avatar {
  width: 38px;
  height: 38px;
}

.modal-wrapper {
  background: transparent !important;
}

.modaaal {
  --background: rgba(255, 255, 255, 0.26) !important;
}

ion-segment.md {
  padding: 0px !important;
}

ion-segment-button.md {
    font-size: 12px;
}